<template>
  <div>
    <Header />
    <pageTitle txt="当月の請求予定" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">店舗名</div>
          <div class="list-head__item col-data">10%税込</div>
          <div class="list-head__item col-data">軽8%税込</div>
          <div class="list-head__item col-data">軽8%値引き</div>
          <div class="list-head__item col-data">合計</div>
          <div class="list-head__item col-btn">
            <div @click="overlay=true, print=true" class="print-btn">
              <btnS class="print" btnTxt="全店分印刷" color="primary" />
            </div>
          </div>
        </li>
        <li class="list-item whole">
          <div class="list-item__data col-name">
            全店分（アスハレコーポレーション宛）
          </div>
          <div class="list-item__data col-data">
            {{ total.ten_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{ total.eight_price | comma }}
          </div>
          <div
            class="list-item__data col-data"
            :class="{ negative: total.service_price < 0 }"
          >
            {{ total.service_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{ (total.eight_price + total.ten_price) | comma }}
          </div>
          <div class="list-item__data col-btn">
            <router-link
              :to="{
                path: '/iseya/invoice/recent/detail/',
                query: {
                  shopID: 0,
                  name: '全店分（アスハレコーポレーション宛）'
                }
              }"
            >
              <btnS
                class="home-btns__item"
                btnTxt="追加処理・詳細"
                color="positive1"
              />
            </router-link>
          </div>
        </li>
        <li class="list-item" v-for="shop in list" :key="shop.id">
          <div class="list-item__data col-name">
            {{ shop.name }}
          </div>
          <div class="list-item__data col-data">
            {{ shop.ten_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{ shop.eight_price | comma }}
          </div>
          <div
            class="list-item__data col-data"
            :class="{ negative: shop.service_price < 0 }"
          >
            {{ shop.service_price | comma }}
          </div>
          <div class="list-item__data col-data">
            {{ (shop.eight_price + shop.ten_price) | comma }}
          </div>
          <div class="list-item__data col-btn">
            <router-link
              :to="{
                path: '/iseya/invoice/recent/detail/',
                query: {
                  shopID: shop.id,
                  name: shop.name
                }
              }"
            >
              <btnS
                class="home-btns__item"
                btnTxt="追加処理・詳細"
                color="positive1"
              />
            </router-link>
          </div>
        </li>
      </ul>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <printInvoice
        :allDetail="allDetail"
        v-if="print"
      />
      <div id="fixedMenu" v-if="print">
        <div
          v-on:click="(print = !print), (overlay = !overlay), removeSetPrint()"
        >
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 980px;
  margin: 0 auto 20px;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
}

.list-subhead {
  display: flex;
  height: 25px;
  font-size: 1.2rem;
  letter-spacing: 0em;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;

  .list-item__data {
    justify-content: center;
  }
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
  &.col-btn {
    justify-content: flex-start;
    padding-left: 15px;
  }
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
  &.total {
    &:nth-of-type(even) {
      background: none;
    }
  }
  &.whole {
    position: relative;
    &:before {
      content: "";
      width: 100vw;
      min-width: 1280px;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      right: calc(50% - 50vw);
      bottom: 0;
      transform: translate(0, 0);
      @media screen and (max-width: 1280px) {
        right: -150px;
      }
    }
    &:after {
      content: "";
      width: 100vw;
      min-width: 1280px;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      right: calc(50% - 50vw);
      bottom: -2px;
      transform: translate(0, 0);
      @media screen and (max-width: 1280px) {
        right: -150px;
      }
    }
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.col-data {
    justify-content: flex-end;
  }
  &.negative {
    color: variables.$negative;
  }
}

.col-name {
  width: 304px;
}

.col-data {
  width: 130px;
}

.col-btn {
  width: 155px;
}

.pager-wrap {
  width: 900px;
  margin: 0 auto;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import printInvoice from "@/components/iseya/printInvoice";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    printInvoice
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      today: "",
      list: [],
      total: {},
      allDetail: [],
      overlay: false,
      print: false,
      printDate: "",
      weekday: "",
    };
  },
  async created() {
    const api = new ApiClient();
    const param = {};
    const result = await api.post("/iseya/invoice/list", param);
    this.list = result.list;
    this.total = result.total;
    console.log("result");
    console.log(result);
    console.log("----------");

    const allDetail = await api.post("/iseya/invoice/all_detail", param);
    this.allDetail = allDetail.list;

    //allDetail[n].list[i].total_priceが1以上もしくは-1以下のものを抽出
    this.allDetail = this.allDetail.map(shop => {
      // total_priceが1以上または-1以下のアイテムのみを抽出
      const filteredList = shop.list.filter(item => item.total_price >= 1 || item.total_price <= -1);
      console.log("filteredList");
      console.log(filteredList);
      // フィルタリングされたリストでshopオブジェクトを更新
      return {
        ...shop,
        list: filteredList
      };
    });
  }
};
</script>
